.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	transition: .5s;
	text-decoration: none;
	cursor: pointer;
	text-align: center;
	background-color: transparent;
	&_menu {

	}
	&_theme1 {
		border: 1px solid $green;
		font-weight: 500;
		text-transform: uppercase;
		font-size: 11px;
		line-height: 11px;
		width: 255px;
		height: 42px;
		position: relative;
		color: $green;
		letter-spacing: 1px;
		&:hover {
			background: $green;
			color: #fff;
		}
		&:active {
			background: #6eae2a;
		}

		&_white {
			border-color: #fff;
			color: #fff;
			&:hover {
				background: #fff;
				color: $green;
			}
		}
		&_green2 {
			background: $green;
			color: #fff;
			&:hover {
				background: transparent;
				color: $green;
			}
		}
		&_white2 {
			background: #fff;
			color: #333333;
			border-color: #fff;
			&:hover {
				background: transparent;
				color: #fff;
			}
		}
	}

	&_menu {
		display: none;
		position: relative;
		z-index: 160;
		top: 0;
		width: 20px;
		height: 15px;
		z-index: 220;
		cursor: pointer;
		& span,
		&:before,
		&:after{
			content: "";
			display: block;
			width:20px;
			height: 3px;
			background-color: #333;
			position: absolute;
			left: 0;
			transition: all 300ms ease-in-out;
			border-radius: 20px;
		}

		&:before{
			top:0px;
		}
		& span{
			top:6px;
			width: 16px;
			opacity:1;
		}
		&:after{
			top:12px;
		}
		&.active {
			span {
				opacity:0;
				top:5px;
				width: 20px;
				transform:rotate(45deg);
			}
			&:before {
				width:20px;
				top:5px;
				left: 0;
				transform:rotate(45deg);
			}
			&:after {
				width:20px;
				top:5px;
				left: 0;
				transform:rotate(-45deg);
			}
		}
	}

	&_booking {
		position: absolute;
		left: calc(50% + 485px);
		bottom: 100px;
		z-index: 10;
		&:hover {
			.btn_booking {
				&__main {
					background: lighten($green,3%);
				}
			}	
		}
		&__main {
			width: 70px;
			height: 70px;
			background: $green;
			position: relative;
			border-radius: 100%;
			transition: .5s;
			&:before {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%,-50%) rotate(28deg);
				width: 33px;
				height: 33px;
				background: url(../img/icon-calendar2.svg) no-repeat;
			}
		}
		&__circle1 {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			transform-origin: center;
			border: 1px solid $green;
			width: 140px;
			height: 140px;
			box-sizing: border-box;
			opacity: .4;
			border-radius: 100%;
			animation: ripple1 2s ease-out infinite;
		}
		&__circle2 {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			transform-origin: center;
			border: 1px solid $green;
			width: 210px;
			height: 210px;
			box-sizing: border-box;
			opacity: .4;
			border-radius: 100%;
			animation: ripple2 2.5s ease-out infinite;
		}
	}

}
@keyframes ripple1 {
  0% {
		width: 100px;
		height: 100px;
		opacity: .5;
  }
  100% {
		width: 160px;
		height: 160px;
		opacity: .02;
  }
}
@keyframes ripple2 {
  0% {
		width: 160px;
		height: 160px;
		opacity: .5;
  }
  100% {
		width: 230px;
		height: 230px;
		opacity: .02;
  }
}
