.promo {
	position: relative;
	&__item {
		background-position: center 0;
		background-repeat: no-repeat;
		color: #fff;
		position: relative;
		height: 100vh;
		&__overlay {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(34,34,34,.3);
		}
		.container {
			position: relative;
			z-index: 10;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			height: 100%;
		}
		&__content {
			padding-bottom: 94px;
			max-width: 780px;
		}
	}
	&__title1 {
		font-size: 90px;
		line-height: 90px;
		font-weight: 300;
		margin-bottom: 35px;
	}
	&__title2 {
		font-size: 40px;
		line-height: 40px;
		font-weight: 300;
		margin-bottom: 40px;
		letter-spacing: 4.4px;
	}
	&__text {
		font-size: 13px;
		line-height: 26px;
		padding-left: 30px;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 5px;
			width: 1px;
			bottom: 5px;
			background: #fff;
		}
	}
}

.bg-line {
	position: absolute;
	width: 1px;
	height: 100%;
	background: rgba(255,255,255,.1);
	&_pos1 {
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	&_pos2 {
		top: 0;
		left: calc(50% - 380px);
		transform: translateX(-50%);
	}
	&_pos3 {
		top: 0;
		left: calc(50% + 380px);
		transform: translateX(-50%);
	}
}
.header {
	padding-top: 29px;
	padding-bottom: 29px;
	border-bottom: 1px solid #e8e8e8;
	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.menu {
	list-style: none;
	display: flex;
	&>li {
		margin-right: 44px;
		&:last-child {
			margin-right: 0;	
		}
		&>a {
			color: #333333;
			font-size: 14px;
			text-decoration: none;
		}
	}
}

.title-main {
	font-size: 36px;
	line-height: 44px;
	color: $green;
	font-weight: 300;
	padding-left: 140px;
	padding-right: 140px;
	box-sizing: border-box;
	max-width: 920px;
	margin: 0 auto 25px;
	text-align: center;
	position: relative;
	&:after {
		content: "";
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		height: 1px;
		width: 63px;
		background: $green;
	}
	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		height: 1px;
		width: 63px;
		background: $green;
	}
}

.info-block {
	display: flex;
	align-items: center;
	margin: 0 -15px 101px;
	position: relative;
	&:last-child {
		margin-bottom: 0;	
	}
	&__bg-dots {
		position: absolute;
		left: -15px;
		top: 50%;
		transform: translateY(-50%);
		width: calc(100% + 30px);
		height: 100%;
		background: url(../img/bg-dots.png) repeat-x center;
		z-index: -1;
	}
	&__img {
		margin: 0 15px;
		width: 50%;
		line-height: 0;
		box-shadow: 0 5px 15px 0 rgba(0,0,0,.15);
	}
	&__desc {
		margin: 0 15px;
		padding-left: 35px;
		width: 50%;	
		box-sizing: border-box;
	}
	&__title {
		text-transform: uppercase;
		letter-spacing: .2px;
		margin-bottom: 20px;
		text-align: center;
		font-weight: 700;
		font-size: 30px;
		line-height: 36px;
	}
	&__text {
		font-size: 13px;
		line-height: 26px;
		display: flex;
		text-align: justify;
		text-align-last: center;
	}
	&_desc-first {
		.info-block {
			&__desc {
				order: -1;
				padding-left: 0;
				padding-right: 35px;
			}
		}
	}

}

.gallery {
	&__item {
		text-align: center;
		line-height: 0;
	}
}

.text {
	max-width: 920px;
	margin: 0 auto;
	p {
		margin-bottom: 25px;
		font-size: 13px;
		line-height: 26px;
		text-align: center;
	}
}

.bg-booking {
	color: #fff;
	background: url(../img/bg-sunset.jpg) no-repeat center -850px;
	text-align: center;
	display: flex;
	align-items: center;
	height: 300px;
	box-sizing: border-box;
	padding-top: 60px;
	position: relative;
	.container {
		position: relative;
		z-index: 10;
	}
	&__title {
		text-align: center;
		font-size: 24px;
		line-height: 30px;
		font-weight: 300;
		margin-bottom: 40px;
	}
	&__btn {
		.btn {
			width: 300px;
		}
	}
	&__bg-overlay {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(34,34,34,.6);
	}
}

.prices {
	max-width: 730px;
	margin: 0 auto;
	background: #fff;
	box-shadow: 0 5px 15px 0 rgba(0,0,0,.15);
	&__title {
		font-size: 20px;
		line-height: 26px;
		color: #fff;
		height: 89px;
		text-transform: uppercase;
		font-weight: 700;
		background: $green;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__section {
		padding: 27px 27px 38px;
	}
	&__main {
		border-bottom: 1px solid #f3f3f3;
		padding-bottom: 29px;
		margin-bottom: 22px;
	}
	&__item {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		font-size: 14px;
		margin-bottom: 16px;
		&:last-child {
			margin-bottom: 0;	
		}
		&__dots {
			display: inline-block;
			position: relative;
			top: -5px;
			border-bottom: 1px dotted #e0e0e0;
			flex-grow: 1;
		}
	}
	&__text {
		color: #888888;
		line-height: 26px;
		text-align: center;
		margin-bottom: 25px;
	}
	&__btn {
		text-align: center;
	}
}

.reviews {
	background: url(../img/bg-water-boat.jpg) no-repeat center 0;
	position: relative;
	padding-top: 95px;
	padding-bottom: 103px;
	.title-def {
		color: #fff;
		margin-bottom: 35px;
	}
	&__inner {
		position: relative;
		z-index: 10;
	}
	&__items {
		display: flex;
		align-items: flex-start;
		margin: 0 -30px;
	}
	&__bg-overlay {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(34,34,34,.4);
	}
	&__item {
		position: relative;
		background: #fff;
		width: 540px;
		margin: 20px 15px;
		box-sizing: border-box;
		padding: 40px 30px 33px;
		font-size: 13px;
		line-height: 26px;
		&:before {
			content: "“";
			font-size: 50px;
			line-height: 50px;
			color: #fff;
			position: absolute;
			left: 25px;
			top: -20px;
			width: 40px;
			height: 40px;
			border-radius: 100%;
			box-sizing: border-box;
			background: $green;
			padding-top: 7px;
			text-align: center;
		}
		&.zindex2, &.zindex3, &.zindex4 {
			opacity: .5;
		}
		&__block {
			margin-bottom: 25px;
		}
		&__author {
			text-align: right;
			margin-top: -12px;
		}
		&__logo {
			text-align: right;
		}
	}
	&__btn {
		text-align: center;
		margin-top: 27px;
	}
}

.reviews-slider {
	&.slider-theme {
		.slick-dots {
			position: static;
			margin: 7px 0 0;
		}
	}
}

.title-def {
	text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    &_small {
    	font-size: 14px;
    	letter-spacing: 20px;
    	letter-spacing: 0;
    }
}

.places {
	display: flex;
	margin: 0 -15px;
	&__img {
		flex-shrink: 0;
		margin: 0 15px;
		width: 540px;
		line-height: 0;
		&__inner {
			display: inline-block;
			position: relative;
		}
		&__labels {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			display: none;
		}
		&__label {
			background: #fff;
			border-radius: 20px 0 0 20px;
			box-sizing: border-box;
			margin-bottom: 5px;
			width: 240px;
			height: 39px;
			display: flex;
			align-items: center;
			text-align: left;
			padding-left: 30px;
			padding-right: 20px;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 12px;
			line-height: 14px;
			box-shadow: 0 5px 20px 0 rgba(0,0,0,.25);
			&_type1 {
				color: #2579bf;
			}
			&_type2 {
				color: #f24c46;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&__items {
		display: flex;
		width: 100%;
		flex-grow: 1;
		margin: 0 15px;
		&__col {
			width: 50%;
			margin: 0 30px 0 0;
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
	}
	&__item {
		background: #f9f9f9;
		height: 55px;
		box-sizing: border-box;
		padding: 0 20px;
		display: flex;
		align-items: center;
		position: relative;
		transition: .5s;
		cursor: pointer;
		margin-bottom: 15px;
		&:hover {
			box-shadow: 0 5px 20px 0 rgba(0,0,0,.15);
			.places {
				&__item {
					&__more {
						opacity: 1;
						visibility: visible;
					}
				}
			}	
		}
		&__num {
			margin-right: 15px;
			border-radius: 100%;
			color: #fff;
			display: inline-flex;
			font-size: 10px;
			line-height: 10px;
			font-weight: 700;
			flex-shrink: 0;
			align-items: center;
			text-align: center;
			justify-content: center;
			width: 15px;
			height: 15px;
			&_red {
				background: #f24841;
			}
			&_blue {
				background: #2579bf;
			}
		}
		&__title {
			line-height: 18px;
		}
		&__more {
			cursor: default;
			position: absolute;
			z-index: 10;
			top: 22px;
			right: calc(100% - 20px);
			width: 255px;
			background: #fff;
			box-shadow: 0 5px 20px 0 rgba(0,0,0,.15);
			box-sizing: border-box;
			visibility: hidden;
			opacity: 0;
			transition: .5s;
		}
		&__img {
			line-height: 0;
		}
		&__text {
			padding: 20px;
			line-height: 24px;
			font-size: 13px;
			text-align: center;
		}
	}
}


.footer {
	background: url(../img/bg-river.jpg) no-repeat center 0;
	color: #fff;
	position: relative;
	padding-top: 92px;
	.title-def {
		text-align: left;
		margin-bottom: 32px;
	}
	&__bottom {
		background: #000;
		text-align: center;
		font-size: 12px;
		color: #868686;
		padding: 10px 0;
		position: relative;
		z-index: 10;
	}
	&__col-info {
		width: 400px;
		margin-right: 30px;
	}
	&__col-form {
		width: 540px;
	}
	&__main {
		position: relative;
		z-index: 10;
		display: flex;
		justify-content: space-between;
		padding-bottom: 100px;
	}
	&__contacts {
		margin-bottom: 30px;
	}
	&__booking {
		.title-def {
			margin-bottom: 10px;
		}
	}
	&__bg-substrate {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.8);
	}
}

.logo-booking {
	margin-right: 28px;
	text-decoration: none;
}

.contact-item {
	display: flex;
	align-items: center;
	font-size: 14px;
	line-height: 22px;
	margin-bottom: 28px;
	&:last-child {
		margin-bottom: 0;	
	}
	&__icon {
		flex-shrink: 0;
		width: 25px;
		margin-right: 20px;
		line-height: 0;
	}
	a {
		text-decoration: none;
		color: #fff;
	}
}

.mat-field {
	position: relative;
	width: 100%;
	box-sizing: border-box;
	display: inline-block;
	margin-bottom: 25px;
	&__label {
		position: absolute;
		left: 12px;
		top: 13px;
		font-size: 16px;
		transform: translateY(0px);
		transition: .3s;
		cursor: text;
		color: #d0d0d0;
		z-index: 5;
		font-size: 12px;
	}
	&.active {
		.mat-field {
			&__label {
				transform: translateY(-21px);
				color: #fff;
			}
		}
	}
	&__input {
		background: transparent;
		height: 45px;
		border: 0;
		border-bottom: 1px solid #fff;
		position: relative;
		z-index: 10;
		width: 100%;
		box-sizing: border-box;
		padding-left: 12px;
		padding-right: 12px;
		color: #fff;
		font-size: 14px;
		&_textarea {
			height: 130px;
			padding-top: 10px;
		}
	}
	&_size2 {
		width: 50%;
		margin-left: 15px;
		margin-right: 15px;
	}
}

.form-btn {
	text-align: center;
}

.fields-row {
	display: flex;
	margin: 0 -15px;
}
.field-icon {
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
}

.icon-date {
	width: 21px;
	height: 22px;
	background: url(../img/icon-calendar.svg) no-repeat;
	&_black {
		background: url(../img/icon-calendar_black.svg) no-repeat;
	}
}

.slider-theme {
	.slick-arrow {
		position: absolute;
		top: 50%;
		width: 40px;
		height: 40px;
		margin-top: -20px;
		border: 1px solid transparent;
		background-color: transparent;
		transition: .5s;
		z-index: 100;
		font-size: 0;
		&:hover {
			border-color: rgba(255,255,255,.25);	
		}
		&:after {
			content: "";
			position: absolute;
			right: -28px;
			top: 50%;
			transform: translateY(-50%);
			width: 45px;
			height: 31px;
			background: url(../img/icon-arrow.svg) no-repeat;

		}
	}
	.slick-prev {
		left: calc(50% - 605px);
		transform: rotate(180deg);
	}
	.slick-next {
		left: calc(50% + 585px);
		right: 50px;
	}
	.slick-dots {
		position: absolute;
		left: 0;
		bottom: 24px;
		width: 100%;
		text-align: center;
		li {
			display: inline-block;
			margin: 0 5px;
			&.slick-active {
				button {
					background: $green;
				}
			}
			button {
				font-size: 0;
				width: 8px;
				height: 8px;
				background: #fff;
				transition: .5s;
			}
		}
	}
}

.section-about {
	padding-top: 93px;
	padding-bottom: 100px;
	&__desc {
		padding-bottom: 70px;
	}
}

.section-desc {
	padding-top: 90px;
	padding-bottom: 72px;
}

.section-prices {
	padding-top: 100px;
	padding-bottom: 100px;
}

.section-places {
	padding-top: 90px;
	padding-bottom: 90px;
	.title-def {
		letter-spacing: .5px;
		margin-bottom: 35px;
	}
}

.text-more {
	display: none;
	text-align: center;
	margin-top: -15px;
}

.win-opened {
	overflow: hidden;
}

.popup {
    opacity: 0;
    visibility: hidden;
    transition: .5s;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 350;
    &__mask {
        position: fixed;
        left: 0;
        top: 0;
        width: calc(100% - 16px);
        height: 100%;
        background: rgba(0,0,0,.22);
    }
    &__wrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
    }
    &__container {
        position: relative;
        z-index: 20;
        display: inline-block;
        box-sizing: border-box;
        background: #fff;
        box-shadow: 0 5px 15px 0 rgba(0,0,0,.15);
        width: 100%;
        margin-top: 80px;
        margin-bottom: 150px;
        transition: .5s;
        text-align: left;
        max-width: 600px;
        padding: 45px 30px;
    }
    &.active {
        opacity: 1;
        visibility: visible;
    }
    &__close {
        position: absolute;
        right: 10px;
        top: 6px;
        cursor: pointer;
        transition: .3s;
        &:before {
            content: "✕";
            display: inline-block;
            color: #000;
            transition: .3s;
        }
    }
}

.popup-close {
	width: 30px;
	height: 30px;
	border: 1px solid #f1f1f1;
	border-radius: 100%;
	position: absolute;
	right: 30px;
	top: 30px;
	z-index: 60;
	cursor: pointer;
	transition: .5s;
	&:before {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		width: 13px;
		height: 13px;
		background: url(../img/icon-cross.svg) no-repeat;
	}
	&:hover {
		background: #f1f1f1;
		&:before {
			background: url(../img/icon-cross_white.svg) no-repeat;
		}
	}

}

.popup-form {
	.title-def {
		color: $green;
		margin-bottom: 40px;
	}
}

.popup-success {
	height: 500px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 13px;
	.title-def {
		margin-bottom: 20px;
	}

}

.field_theme2 {
	.mat-field {
		&__input {
			border-color: #e8e8e8;
			color: #000;
			font-size: 12px;

		}
		&__label {
			color: #888888;
			font-size: 12px;
			text-transform: none;
			font-weight: 400;
			letter-spacing: 0;
			top: 7px;
		}
	}
	&.active {
		.mat-field {
			&__label {
				color: #888888;
			}
		}
	}
}

.btn-trigger {
	display: none;
}

.video-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    background-size: cover;
}
 
.video-bg > video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%; 
    min-height: 100%;
    width: auto;
    height: auto;
    background-size: cover;
}
 
 @supports (object-fit: cover) {
     .video-bg > video {
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         object-fit: cover;
     }
 }


















