@media (max-width: 992px) {
	
	.container {
		padding-left: 15px;
		padding-right: 15px;
	}

	.promo {
		&__item {
			background-size: cover;
			&.slick-slide {
				height: 430px;
			}
		}
		&__title1 {
			font-size: 50px;
			line-height: 50px;
			margin-bottom: 15px;
		}
		&__title2 {
			font-size: 22px;
			line-height: 28px;
			margin-bottom: 20px;
		}
		&__item {
			&__content {
				padding-left: 85px;
				padding-bottom: 40px;
				padding-right: 70px;
			}
		}
	}

	.title-main {
		font-size: 30px;
		line-height: 40px;
		padding-left: 80px;
		padding-right: 80px;
	}

	.section-about {
		padding-top: 50px;
		&__desc {
			padding-bottom: 30px;
		}
	}

	.info-block {
		margin-bottom: 50px;
		&__desc {
			padding-left: 0;
		}
		&__title {
			font-size: 24px;
			line-height: 30px;
		}
		&_desc-first {
			.info-block {
				&__desc {
					padding-right: 0;
				}
			}
		}
	}

	.section-desc {
		padding-top: 50px;
		padding-bottom: 25px;
	}

	.bg-booking {
		&__title {
			font-size: 20px;
			line-height: 30px;
		}
	}

	.section-prices {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.title-def {
		font-size: 24px;
		line-height: 30px;
	}

	.reviews {
		padding-top: 50px;
		padding-bottom: 50px;
		&__item {
			&.zindex1 {
				opacity: .5;
			}
		}
	}

	.places {
		&__img {
			margin: 0;
		}
		&__items {
			flex-direction: column;
			max-height: 630px;
			overflow-y: scroll;
			border-right: 2px solid #d9d9d9;
			&__col {
				width: 100%;
			}
		}
		&__item {
			&__more {
				right: auto;
				left: 15px;
			}
		}
	}

	.footer {
		&__col-form {
			width: 355px;
		}
		&__booking {
			.title-def {
				font-size: 14px;
				line-height: 20px;
				margin-bottom: 20px;
			}
		}
	}

	.title-def {
		letter-spacing: 1px;
	}

	.header {
		padding-top: 14px;
		padding-bottom: 14px;
		.btn_theme1 {
			width: 200px;
		}
	}

	.btn_menu {
		display: block;
		transition: 0s;
		&.active {
			position: fixed;
			left: 15px;
			top: 15px;
		}
	}

	.menu {
		visibility: hidden;
		z-index: 100;
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #fff;
		overflow-y: auto;
		transition: .5s;
		transform: translateX(-100%);
		flex-direction: column;
		align-items: center;
		justify-content: center;
		&>li {
			margin: 0 0 20px;
			&:last-child {
				margin-bottom: 0;	
			}
		}
		&.active {
			transform: translateX(0);
			visibility: visible;
		}
	}

	.popup {
		&__mask {
			width: 100%;
		}
	}

	
	
}