/*@import "../sprite/sprite.scss";*/

@import "core/colors";
@import "core/base";
@import "core/fonts";
@import "core/btns";

@import "layout/header";
@import "layout/content";
@import "layout/footer";

@import "plugins/slick";
@import "plugins/datepicker";

@import "core/media_large";
@import "core/media_tablet";
@import "core/media_mob";