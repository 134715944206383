@font-face {
    font-family: 'Gotham Pro';
    src: url('../fonts/GothamPro-Bold.eot');
    src: local('Gotham Pro Bold'), local('GothamPro-Bold'),
        url('../fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamPro-Bold.woff2') format('woff2'),
        url('../fonts/GothamPro-Bold.woff') format('woff'),
        url('../fonts/GothamPro-Bold.ttf') format('truetype'),
        url('../fonts/GothamPro-Bold.svg#GothamPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url('../fonts/GothamPro-Medium.eot');
    src: local('Gotham Pro Medium'), local('GothamPro-Medium'),
        url('../fonts/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamPro-Medium.woff2') format('woff2'),
        url('../fonts/GothamPro-Medium.woff') format('woff'),
        url('../fonts/GothamPro-Medium.ttf') format('truetype'),
        url('../fonts/GothamPro-Medium.svg#GothamPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url('../fonts/GothamPro-Light.eot');
    src: local('Gotham Pro Light'), local('GothamPro-Light'),
        url('../fonts/GothamPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamPro-Light.woff2') format('woff2'),
        url('../fonts/GothamPro-Light.woff') format('woff'),
        url('../fonts/GothamPro-Light.ttf') format('truetype'),
        url('../fonts/GothamPro-Light.svg#GothamPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url('../fonts/GothamPro.eot');
    src: local('Gotham Pro'), local('GothamPro'),
        url('../fonts/GothamPro.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamPro.woff2') format('woff2'),
        url('../fonts/GothamPro.woff') format('woff'),
        url('../fonts/GothamPro.ttf') format('truetype'),
        url('../fonts/GothamPro.svg#GothamPro') format('svg');
    font-weight: normal;
    font-style: normal;
}