@media (min-width: 1920px) {
	.promo__item, .bg-booking, .reviews, .footer {
		background-size: cover;
	}
}

@media (max-width: 1300px) {
	.slider-theme {
		.slick-prev {
			left: 30px;
		}
		.slick-next {
			right: 30px;
			left: auto;
		}
	}

	.btn_booking {
		left: auto;
		right: 30px;
		bottom: 30px;
	}
			

}

@media (max-width: 1169px) {
	.places {
		&__img {
			width: 450px;
		}
	}

	.menu {
		&>li {
			margin-right: 30px;
		}
	}
}