@media (max-width: 767px) {
	
	.promo {
		&__item {
			height: 90vh;
			&__content {
				padding: 0 0 25px;
			}
		}
		&__text {
			font-size: 12px;
			line-height: 24px;
			padding-left: 0;
			&:before {
				display: none;
			}
		}
		&__title1 {
			font-size: 44px;
			line-height: 50px;
		}
		&__title2 {
			font-size: 20px;
			line-height: 26px;
			letter-spacing: 1.5px;
		}
	}

	.title-main {
		font-size: 24px;
		line-height: 30px;
		padding-left: 0;
		padding-right: 0;
		&:before, &:after {
			display: none;
		}
	}

	.info-block {
		flex-direction: column;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 40px;
		&__img {
			display: inline-block;
			margin: 0 auto 30px;
			width: 100%;
			max-width: 540px;
		}
		&__desc {
			margin: 0;
			width: 100%;
		}
		&_desc-first {
			.info-block {
				&__desc {
					order: 2;
				}
			}
		}
	}

	.section-about {
		padding-bottom: 50px;
	}

	.bg-booking {
		padding-top: 20px;
		&__btn {
			.btn {
				width: 290px;
			}
		}
	}

	.prices {
		&__item {
			flex-direction: column;
			align-items: center;
			&__dots {
				display: none;
			}
		}
		&__section {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.reviews {
		background-size: cover;
		padding-bottom: 30px;
		&__items {
			margin-left: 0;
			margin-right: 0;
		}
		&__item {
			width: auto;
			margin-left: 15px;
			margin-right: 15px;
			padding-left: 15px;
			padding-right: 15px;
			padding-bottom: 20px;
			padding-top: 40px;
		}
	}

	.places {
		&__img {
			width: 100%;
			text-align: center;
			&__labels {
				display: block;
			}
		}
		&__items {
			display: none;
		}
	}

	.section-places {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.footer {
		background-size: cover;
		padding-top: 40px;
		&__main {
			flex-direction: column;
		}
		&__col-info {
			width: 100%;
			margin-bottom: 30px;
		}
		&__col-form {
			width: 100%;
		}
		&__main {
			padding-bottom: 40px;
		}
	}

	.mat-field {
		.icon-date {
			right: 10px;
		}
	}
	
	.text-more {
		display: block;
	}

	.text-more-link {
		color: $green;
		font-size: 13px;
		font-weight: 500;
	}

	.text-hidden {
		display: none;
	}

	.header {
		padding-top: 5px;
		padding-bottom: 5px;
		.btn_theme1 {
			width: 160px;
			height: 27px;
			font-size: 10px;
			line-height: 14px;
		}
	}

	.popup-form {
		.fields-row {
			flex-wrap: wrap;
			margin: 0;
		}
		.mat-field_size2 {
			width: 100%;
			margin-right: 0;
			margin-left: 0;
		}
	}

	.popup {
		&__container {
			padding-left: 10px;
			padding-right: 10px;
		}
	}


	
}